<template>
  <div class="cart">
    <h1>{{ this.$t("cart.title") }}</h1>

    <div class="header">
      <input
        type="checkbox"
        :checked="all_selected"
        @change="change_all_cart_checkbox($event)"
      />
      <label for="check_select_all">{{ this.$t("cart.select_all") }}</label>
      <div class="delete" @click="delete_cart">
        {{ this.$t("cart.delete_selected") }}
      </div>
    </div>

    <div class="body">
      <div class="no-data" v-if="cart_data.length <= 0">
        {{ this.$t("cart.no_data") }}
      </div>
      <ul v-if="cart_data.length > 0">
        <li v-for="(value, index) in cart_data" :key="index">
          <div class="check">
            <input
              type="checkbox"
              :checked="value.selected"
              @change="change_cart_checkbox(value.cart_id, $event)"
            />
          </div>
          <div class="product">
            <div
              class="image"
              :style="{
                backgroundImage: 'url(' + value.product_image_url + ')',
              }"
            ></div>
            <div class="desc">
              <div class="seller">{{ value.seller_name }}</div>
              <div class="product_name">
                <router-link :to="'/product/detail/' + value.product_id">{{
                  value.product_name
                }}</router-link>
              </div>
            </div>
          </div>
          <div class="option">
            <div class="combination">{{ value.product_option }}</div>
            <div class="sum">
              <Counter
                :count="value.product_count"
                @increase="
                  (counter) => {
                    this.change_product_count(value.cart_id, counter);
                  }
                "
                @decrease="
                  (counter) => {
                    this.change_product_count(value.cart_id, counter);
                  }
                "
              />
              <div class="price">
                <div class="percent">
                  {{
                    this.common.number_format(
                      ((value.retail_price +
                        value.extra_price -
                        (value.sale_price + value.extra_price)) /
                        (value.retail_price + value.extra_price)) *
                        100.0,
                      1
                    )
                  }}%
                </div>
                <div class="sale">
                  {{
                    this.common.currency_format(
                      (value.sale_price + value.extra_price) *
                        value.product_count,
                      this.unit
                    )
                  }}
                </div>
                <div class="retail">
                  {{
                    this.common.currency_format(
                      (value.retail_price + value.extra_price) *
                        value.product_count,
                      this.unit
                    )
                  }}
                </div>
              </div>
            </div>
            <div class="close"></div>
          </div>
        </li>
      </ul>
    </div>

    <div class="summary" v-if="cart_data.length > 0">
      <div class="detail">
        <div class="block">
          <div class="title">{{ this.$t("cart.retail_price") }}</div>
          <div class="price">
            {{
              this.common.currency_format(this.total_retail_price, this.unit)
            }}
          </div>
        </div>
        <div class="minus"></div>
        <div class="block">
          <div class="title">{{ this.$t("cart.discount") }}</div>
          <div class="price">
            {{ this.common.currency_format(this.total_discount, this.unit) }}
          </div>
        </div>
        <div class="plus"></div>
        <div class="block">
          <div class="title">{{ this.$t("cart.shipping") }}</div>
          <div class="price">ALL FREE</div>
        </div>
      </div>
      <div class="total">
        <div class="title">{{ this.$t("cart.total_price") }}</div>
        <div class="price">
          {{ this.common.currency_format(this.total_price, this.unit) }}
        </div>
      </div>
    </div>

    <button
      class="button red buy compact"
      @click="click_buy"
      v-if="cart_data.length > 0"
    >
      {{ this.$t("cart.button_buy") }}
    </button>

    <button
      class="button red buy compact"
      @click="this.$router.push('/')"
      v-if="cart_data.length <= 0"
    >
      {{ this.$t("cart.button_main") }}
    </button>
  </div>

  <Spinner v-show="processing" />
</template>

<script>
import Counter from "@/views/product/Counter.vue";
export default {
  name: "Cart",
  components: {
    Counter,
  },
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.cart"),
      og: {
        title: this.$t("meta.title") + " :: " + this.$t("meta.cart"),
      },
    };
  },
  data: function () {
    return {
      process_queue: [],
      cart_data: [],

      all_selected: false,

      total_price: 0,
      total_retail_price: 0,
      total_discount: 0,

      invoice_token: "",

      unit: "KRW",
    };
  },
  watch: {
    cart_data: {
      handler: function () {
        console.log("Cart data changed.");
        let sum_retail_price = 0;
        let sum_discount = 0;
        let selected_counter = 0;
        for (let i = 0; i < this.cart_data.length; i++) {
          if (this.cart_data[i].selected) {
            let amount = this.cart_data[i].product_count;

            sum_discount +=
              (this.cart_data[i].retail_price - this.cart_data[i].sale_price) *
              amount;
            sum_retail_price +=
              (this.cart_data[i].retail_price + this.cart_data[i].extra_price) *
              amount;

            selected_counter++;
          }
        }

        this.total_discount = sum_discount;
        this.total_retail_price = sum_retail_price;
        this.total_price = sum_retail_price - sum_discount;

        // 전체 선택 여부 계산
        if (selected_counter > 0 && this.cart_data.length == selected_counter) {
          this.all_selected = true;
        } else {
          this.all_selected = false;
        }
      },
      deep: true,
    },
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  mounted() {
    let self = this;
    this.common.sync_user_cart().then(function () {
      self.load_cart_data();
    });

    // 인보이스 토큰
    let naive_token = this.common.generate_random_id();
    this.invoice_token = this.$CryptoJS.MD5(naive_token.toString()).toString();
  },
  methods: {
    load_cart_data: function () {
      let self = this;

      // 로그인 되어 있으면 DB에서 가져오기
      if (this.storage.is_logged_in()) {
        self.process_queue.push(1);

        this.axios
          .post(
            process.env.VUE_APP_API_URL + "/cart/list",
            {
              user_id: self.storage.get_user_id(),
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then(function (response) {
            self.process_cart_result_data(response.data);
          })
          .finally(function () {
            self.process_queue.pop();
          }); //-- axios
      } else {
        // 로그인 안되어 있으면 Storage 정보를 DB에서 파싱해서 가져오기

        let storage_cart_data = this.storage.get_user_cart();

        self.process_queue.push(1);
        this.axios
          .post(
            process.env.VUE_APP_API_URL + "/cart/parse",
            {
              storage_cart_data: storage_cart_data,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then(function (response) {
            self.process_cart_result_data(response.data);
          })
          .finally(function () {
            self.process_queue.pop();
          }); //-- axios
      } //-- if-else
    }, //-- load_cart_data

    // 카트 정보 API 후속 처리
    process_cart_result_data: function (data) {
      this.cart_data = [];

      for (let i = 0; i < data.length; i++) {
        let item = {
          cart_id: data[i].cart_id,
          product_option: data[i].product_option,
          product_count: parseInt(data[i].product_count),
          combination_id: data[i].product_option_combination_id,
          extra_price:
            data[i].product_option_combination_id <= 0
              ? 0
              : parseFloat(data[i].extra_price),
          combination_status: data[i].combination_status,
          cart_date: data[i].cart_date,

          product_id: data[i].product_id,
          product_name: data[i].product_name,
          product_image: data[i].product_images.split("|")[0],
          retail_price: parseFloat(data[i].retail_price),
          sale_price: parseFloat(data[i].sale_price),
          seller_id: data[i].seller_id,
          seller_name: data[i].seller_name,

          selected: true,
        };

        item.product_image_url =
          process.env.VUE_APP_API_URL +
          "/resource/get/product/" +
          item.product_id +
          "/" +
          item.product_image;

        this.unit = data[i].unit;

        this.cart_data.push(item);
      }
    },

    change_all_cart_checkbox: function ($event) {
      for (let i = 0; i < this.cart_data.length; i++) {
        if ($event.target.checked) {
          this.cart_data[i].selected = true;
        } else {
          this.cart_data[i].selected = false;
        }
      }
    },

    change_cart_checkbox: function (cart_id, $event) {
      for (let i = 0; i < this.cart_data.length; i++) {
        let str_cart_id = this.cart_data[i].cart_id.toString();

        if (cart_id.toString() === str_cart_id) {
          if ($event.target.checked) {
            this.cart_data[i].selected = true;
          } else {
            this.cart_data[i].selected = false;
          }
          break;
        }
      }
    },

    change_product_count: function (cart_id, count) {
      for (let i = 0; i < this.cart_data.length; i++) {
        let str_cart_id = this.cart_data[i].cart_id.toString();

        if (count < 1) count = 1;
        if (cart_id.toString() === str_cart_id) {
          this.cart_data[i].product_count = count;
          this.update_cart_amount(cart_id, count);
          break;
        }
      }
    },

    update_cart_amount: function (cart_id, count) {
      let self = this;

      if (this.storage.is_logged_in()) {
        self.process_queue.push(1);
        this.axios
          .post(
            process.env.VUE_APP_API_URL + "/cart/update",
            {
              user_id: self.storage.get_user_id(),
              cart_id: cart_id,
              product_count: count,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then(function (response) {
            if (response.data.result != "OK") {
              self.$swal.fire({
                text: self.$t("cart.error_occur"),
                icon: "error",
              });
              return;
            }
          })
          .finally(function () {
            self.process_queue.pop();
          }); //-- axios
      } else {
        // Storage 데이터 갱신
        let user_cart = this.storage.get_user_cart();
        for (let i = 0; i < user_cart.length; i++) {
          if (user_cart[i].cart_id.toString() == cart_id.toString()) {
            user_cart[i].product_count = count;
            break;
          }
        }

        this.storage.set_user_cart(user_cart);
      }
    },

    delete_cart: function () {
      let self = this;

      self.$swal
        .fire({
          text: self.$t("cart.confirm_delete"),
          icon: "question",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            let cart_ids = [];

            for (let i = self.cart_data.length - 1; i >= 0; i--) {
              if (self.cart_data[i].selected) {
                cart_ids.push(self.cart_data[i].cart_id);
                self.cart_data.splice(i, 1);
              }
            }

            if (cart_ids.length <= 0) {
              self.$swal.fire({
                text: self.$t("cart.error_no_selected"),
                icon: "warning",
              });
              return;
            }

            // 로그인 되어 있으면 DB에 갱신
            if (self.storage.is_logged_in()) {
              self.process_queue.push(1);
              this.axios
                .post(
                  process.env.VUE_APP_API_URL + "/cart/delete",
                  {
                    user_id: self.storage.get_user_id(),
                    cart_ids: cart_ids,
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                )
                .then(function (response) {
                  if (response.data.result != "OK") {
                    self.$swal.fire({
                      text: self.$t("cart.error_occur"),
                      icon: "error",
                    });
                    return;
                  } else {
                    if (cart_ids.length <= 0) {
                      self.$swal.fire({
                        text: self.$t("cart.deleted"),
                        icon: "information",
                      });
                      return;
                    }
                  }
                })
                .finally(function () {
                  self.process_queue.pop();
                }); //-- axios
            } else {
              // 로그인 안되어 있다면...

              // Storage 데이터 갱신
              let user_cart = self.storage.get_user_cart();
              for (let i = user_cart.length - 1; i >= 0; i--) {
                for (let j = 0; j < cart_ids.length; j++) {
                  if (
                    user_cart[i].cart_id.toString() == cart_ids[j].toString()
                  ) {
                    user_cart.splice(i, 1);
                    break;
                  }
                }
              }

              this.storage.set_user_cart(user_cart);
            } //-- if else
          }
        }); //-- swal
    }, //-- delete_cart

    click_buy: function () {
      let self = this;
      this.$swal
        .fire({
          text: this.$t("cart.confirm_buy"),
          icon: "question",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            // 인보이스 데이터 만들기
            let invoice_data = [];
            for (let i = 0; i < self.cart_data.length; i++) {
              if (self.cart_data[i].selected) {
                let invoice = {
                  cart_id: self.cart_data[i].cart_id,
                  product_id: self.cart_data[i].product_id,
                  product_option: self.cart_data[i].product_option,
                  product_count: self.cart_data[i].product_count,
                  combination_id: self.cart_data[i].combination_id,
                };

                invoice_data.push(invoice);
              }
            }

            if (invoice_data.length <= 0) {
              self.$swal.fire({
                text: self.$t("cart.error_no_selected"),
                icon: "warning",
              });
              return;
            }

            self.save_invoice(invoice_data);
          }
        });
      return;
    }, //-- click_buy

    // 인보이스를 서버에 저장
    save_invoice: function (invoice_data) {
      let self = this;
      self.process_queue.push(1);

      self.axios
        .post(
          process.env.VUE_APP_API_URL + "/invoice/add",
          {
            user_id: self.storage.get_user_id(),
            invoice_token: self.invoice_token,
            invoice_data: invoice_data,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let result = response.data.result;

          if (result == "OK") {
            let route_data = {
              name: "order",
              params: { invoice_token: self.invoice_token },
            };
            self.$router.push(route_data);
          } else if (result == "ERR_NO_PARAM") {
            self.$swal.fire({
              text: self.$t("common.error_no_param"),
              icon: "error",
            });
            return;
          }
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- save_invoice
  },
};
</script>

<style scoped>
.cart {
  display: block;
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem;

  background: #fff;
}
.cart > .header {
  position: relative;
  border-bottom: 1px solid #dedede;
  padding: 1rem 0;
}
.cart > .header > input[type="checkbox"] {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
}
.cart > .header > label {
  display: inline-block;
  vertical-align: middle;
  font-weight: 700;
  font-size: 1rem;
}
.cart > .header > .delete {
  position: absolute;
  right: 1rem;
  bottom: 50%;
  transform: translate(0, 50%);
  border: 1px solid #ccc;
  padding: 0.3rem 0.6rem;
  font-size: 0.8rem;
  border-radius: 0.4rem;

  cursor: pointer;
}

.cart > .body {
}
.cart > .body > .no-data {
  padding: 4rem 0;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
}
.cart > .body > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.cart > .body > ul > li {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  border-bottom: 1px solid #dedede;
  margin-bottom: 1rem;
}
.cart > .body > ul > li > .check {
  width: 2.5rem;
  text-align: center;

  display: flex;
  align-items: center;
}
.cart > .body > ul > li > .product {
  width: 60%;
  padding: 1rem;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.cart > .body > ul > li > .product > .image {
  width: 120px;
  height: 120px;
  margin-right: 1rem;
  background: #808080;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.cart > .body > ul > li > .product > .desc {
}
.cart > .body > ul > li > .product > .desc > .seller {
  font-size: 1rem;
  color: #808080;
}
.cart > .body > ul > li > .product > .desc > .product_name {
  font-size: 1.1rem;
  font-weight: 700;
}
.cart > .body > ul > li > .product > .desc > .product_name a {
  color: #000;
  text-decoration: none;
}
.cart > .body > ul > li > .product > .desc > .product_name a:hover {
  text-decoration: underline;
}
.cart > .body > ul > li > .option {
  width: calc(40% - 2.4rem - 1.2rem);
  margin: 0.6rem;
  padding: 1rem;

  background: #efefef;
  border-radius: 0.4rem;
}
.cart > .body > ul > li > .option > .combination {
  border-bottom: 1px solid #dedede;
  padding: 0 0 0.4rem 0;
  margin-bottom: 1rem;
}
.cart > .body > ul > li > .option > .sum {
}
.cart > .body > ul > li > .option > .sum > .price {
  margin-top: 0.4rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cart > .body > ul > li > .option > .sum > .price > .percent {
  color: #e74356;
  font-size: 1.2rem;
  font-weight: 700;
  margin-right: 1rem;
}
.cart > .body > ul > li > .option > .sum > .price > .sale {
  color: #000;
  font-size: 1.4rem;
  font-weight: 700;
  margin-right: 1rem;
}
.cart > .body > ul > li > .option > .sum > .price > .retail {
  color: #808080;
  font-size: 1rem;
  font-weight: 300;
  text-decoration: line-through;
}

.cart > .summary {
  display: flex;
  justify-content: center;
  align-items: stretch;

  padding: 2rem 0;
  margin: 2rem 0;

  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.cart > .summary > .detail {
  width: calc(65% - 1px);
  border-right: 1px solid #ccc;
  text-align: center;
}
.cart > .summary > .detail > div {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  margin: 0 1rem;
}
.cart > .summary > .detail > .block > .title {
  font-size: 1.1rem;
  font-weight: 400;
  margin-bottom: 0.4rem;
}
.cart > .summary > .detail > .block > .price {
  font-size: 1.2rem;
  font-weight: 700;
}
.cart > .summary > .detail > .plus,
.cart > .summary > .detail > .minus {
  width: 3rem;
  height: 3rem;
  background: url("../../assets/img/icon_plus.svg") no-repeat center center;
  background-size: 50% auto;
}
.cart > .summary > .detail > .minus {
  background-image: url("../../assets/img/icon_minus.svg");
}
.cart > .summary > .total {
  width: 35%;
  text-align: center;
}
.cart > .summary > .total > .title {
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 0.4rem;
}
.cart > .summary > .total > .price {
  font-size: 1.4rem;
  font-weight: 700;
  color: #e74356;
}

.cart > .buy {
  display: block;
  width: 320px;
  max-width: 80%;
  margin: 1rem auto;
}

@media (max-width: 920px) {
  .cart > .body > ul > li {
    flex-wrap: wrap;
  }
  .cart > .body > ul > li > .product {
    width: 95%;
    max-width: calc(100% - 3rem);
  }
  .cart > .body > ul > li > .option {
    width: calc(100% - 1.2rem);
  }

  .cart > .summary {
    display: block;
  }
  .cart > .summary > .detail {
    width: auto;
    padding: 2rem 0;
    border-right: none;
  }
  .cart > .summary > .detail > div {
    margin: 0 0.4rem;
  }
  .cart > .summary > .detail > .block > .title {
    font-size: 0.875rem;
  }
  .cart > .summary > .detail > .block > .price {
    font-size: 1rem;
  }
  .cart > .summary > .detail > .plus,
  .cart > .summary > .detail > .minus {
    width: 1.6rem;
    height: 1.6rem;
  }

  .cart > .summary > .total {
    width: auto;
    border-top: 1px solid #dedede;
    padding: 2rem 0;
  }
  .cart > .summary > .total > .title {
    font-size: 1.1rem;
    font-weight: 700;
    margin-bottom: 0.4rem;
  }
  .cart > .summary > .total > .price {
    font-size: 1.4rem;
    font-weight: 700;
    color: #e74356;
  }
}
</style>
