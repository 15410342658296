<template>
  <div class="counter">
    <div class="minus" @click="decrease"></div>
    <input type="number" v-model="counter" readonly />
    <div class="plus" @click="increase"></div>
  </div>
</template>

<script>
export default {
  name: "Counter",
  props: ["count"],
  data: function () {
    return {
      counter:
        typeof this.count == "undefined" || this.count <= 0
          ? 1
          : parseInt(this.count),
    };
  },
  methods: {
    decrease: function () {
      this.counter--;
      if (this.counter < 1) this.counter = 1;

      this.$emit("decrease", this.counter);
    },
    increase: function () {
      this.counter++;
      if (this.counter > 99) this.counter = 99;

      this.$emit("increase", this.counter);
    },
  },
};
</script>

<style scoped>
.counter {
  width: 120px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;

  border-radius: 0.4rem;
  border: 1px solid #dedede;
}
.counter > input {
  width: calc(100% / 3);
  height: 100%;
  border: none;
  font-size: 1rem;
  text-align: center;
  border-radius: 0;
  padding-right: 0;
  padding-left: 0;
}
.counter > input:focus {
  border: none;
  box-shadow: none;
}
.counter > input::-webkit-outer-spin-button,
.counter > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.counter > .minus {
  width: calc(100% / 3 - 1px);
  border-right: 1px solid #dedede;
  background: #fff url("../../assets/img/icon_minus.svg") no-repeat center
    center;
  background-size: 36% auto;
  cursor: pointer;
}
.counter > .plus {
  width: calc(100% / 3 - 1px);
  border-left: 1px solid #dedede;
  background: #fff url("../../assets/img/icon_plus.svg") no-repeat center center;
  background-size: 36% auto;
  cursor: pointer;
}
</style>
